import React, { useEffect, useState, useRef, useCallback }  from 'react'

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NumericInput from '../components/NumericInput';
import { callApi, ErrorAlert, setPropValue, getPropValue ,Round }  from '../utils/utils';
import BarcodeReader from '../components/BarcodeReader';
import { ReactComponent as TrashIcon } from 'bootstrap-icons/icons/trash.svg';

function RouteForm (props){
    const [error, setError] = useState(null);
    const [serverUrl] = useState(process.env.REACT_APP_SERVER_URL);
    const [obj, setObj] = useState({});
    const [paymentTypes, setPaymentTypes] = useState(null);
    const [scannedBarcodes, setScannedBarcodes] = useState([]);
    const barcodeInput = useRef(null);
    const prevScannedBarcodesRef = useRef([]);
    const [notSaveFlg, setNotSaveFlg] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        prevScannedBarcodesRef.current = scannedBarcodes;
    }, [scannedBarcodes]);

    useEffect(()=>{
        async function getOriginalObj(){
            if(!props.active ||!props.originalObj ) return;
            setObj(props.originalObj);
         }
        getOriginalObj();
    },[props.active,props.originalObj]);

    const validateScan = useCallback(async function(bc) {
        setError(null);
        if (bc === null || bc==='' ) return;
        if (prevScannedBarcodesRef.current !== null && prevScannedBarcodesRef.current.filter(t => t.barcode === bc).length) {
            setError("Already scanned");
            return;
        }
        if (obj.OrdItems.filter(t => t.Barcode === bc).length >0 ){
            return;
        }
        const result = await callApi('GET', `${serverUrl}/api/Services/Items?barcode=${bc}`);   
        if (result.ErrorMessage) {
            setError(result.ErrorMessage);
            return;
        }
        barcodeInput.current.value = bc;
        setScannedBarcodes(prevScan=>{
            let newData=[...prevScan];
            newData.push({barcode: bc});
            return newData;
        }); 

        let tmpObj = {...obj};
        let oitems = result.data[0];

        if (typeof oitems["Qty"] == "undefined") { oitems["Qty"] =1;}
        if (oitems["Qty"] === 0) { oitems["Qty"] =1;}

        let lineUnitPrice  =Round( (oitems["Qty"] * oitems["UnitPrice"]),2);
        oitems["TotalPrice"] = Round(  (lineUnitPrice  + ( lineUnitPrice *( oitems["rateof"] /100))) ,2);   
        tmpObj.OrdItems.push(oitems);
        tmpObj.TotalPrice +=oitems["TotalPrice"];
        setNotSaveFlg(false);
        setObj(tmpObj);
        barcodeInput.current.value =null;
    }, [serverUrl,obj]);

    const deleteRow = (index) => {
        let bc = obj.OrdItems[index].Barcode;
        prevScannedBarcodesRef.current = prevScannedBarcodesRef.current.filter(t => t.barcode !== bc);
        obj.OrdItems = obj.OrdItems.filter((_, i) => i !== index);
        obj.TotalPrice=0;
        obj.OrdItems.forEach(rowItem => {
            obj.TotalPrice +=rowItem.TotalPrice;
        });
        obj.TotalPrice = Round( obj.TotalPrice,2);
        setObj({ ...obj });
    };   

    useEffect(() => {
        if (barcodeInput.current ===null ) return;
        let bcInputCurrent = barcodeInput.current;
        bcInputCurrent.addEventListener('keydown', onKeyDown);
        function onKeyDown(e) {
            if (e.keyCode === 13 || e.keyCode ===9 ) {
                validateScan(barcodeInput.current.value);
                barcodeInput.current.value = null;
            }
        }
        return function cleanup() {
            bcInputCurrent.removeEventListener('keydown', onKeyDown);
        };
    });
    useEffect(()=>{
        async function getDropDownList(){
            try{
                setError(null);
                if (!props.active|| paymentTypes) return;
                const request =await callApi('GET', `${serverUrl}/api/Services/PaymentTypes` );
                setPaymentTypes(request);
            }
            catch(err){
                setError(err.message);                
            }
        };
        getDropDownList();
    } ,[serverUrl,props.active,paymentTypes]);

    useEffect(() => {
        setIsDisabled(GetDisabled());
    }, [obj]);

    const GetDisabled = () => {
        if (!obj) return true;
        setNotSaveFlg(true);
        if (obj.Status === 1) return true;
        setNotSaveFlg(false);
        if (parseInt(obj.OrderType) ===0 ) return true;
        if (parseInt(obj.OrderType) ===1 )return true;     
        return false;
    };

    async function setObjectValue(column, value) {
        setPropValue(obj, column, value);
        if (column ==="PaymentTypeId"){
            let  tmppayment  = paymentTypes.find(payment => payment.PaymentTypeId === parseInt(obj.PaymentTypeId));
            if (tmppayment){
                obj["CashFlg"]=  tmppayment.CashFlg 
                if (tmppayment.CashFlg ){ setObjectValue("Deposit", 0);}
  
            }
          }
           if (column ==="OrderType"){
               if (obj.OrderType===0 || obj.OrderType===1 ) {
                 setIsDisabled(true) }
               else {
                setIsDisabled(false) 
               }
           }
          setObj({ ...obj });
    }
    async function setLineObjectValue(column,lineIndex, value) {
        let tmpObj = { ...obj };
        let line = tmpObj.OrdItems[lineIndex]
        setPropValue(line, column,parseFloat(value));
        if (typeof line["Qty"] == "undefined") { line["Qty"] =1;}
        if (line["Qty"] === 0) { line["Qty"] =1;}

        let lineUnitPrice  =Round( (line["Qty"] * line["UnitPrice"]),2);
        line["TotalPrice"] = Round(  (lineUnitPrice  + ( lineUnitPrice *( line["rateof"] /100))) ,2);   
        tmpObj.OrdItems[lineIndex]=line;
        tmpObj.TotalPrice=0;
        tmpObj.OrdItems.forEach(rowItem => {
            tmpObj.TotalPrice +=rowItem.TotalPrice;
        });
        tmpObj.TotalPrice = Round( tmpObj.TotalPrice,2);
        setObj(tmpObj);
    }

    function createPaymentDropDownList(){
        if (!paymentTypes || !obj) return null;
        return (
            <Form.Control
                as="select"
                id='PaymentTypeId'
                size="sm"
                disabled ={isDisabled}
                value={getPropValue(obj, "PaymentTypeId") || null}
                onChange={e => setObjectValue("PaymentTypeId", e.target.value)}>
                <option value={null}></option>
                {paymentTypes.map((lkpObj, i) =>
                    <option value={lkpObj['PaymentTypeId']}>{lkpObj['Descr']}</option>
                )}
            </Form.Control>
        );
    }
    
    function createNewOrderList(){
        if (!paymentTypes || !obj) return null;
        return (
            <Form.Control
                as="select"
                size="sm"
                disabled ={isDisabled &&  (parseInt(obj.OrderType) < 1 || obj.Status ===1)}
                value={getPropValue(obj, "OrderType")}
                onChange={e => setObjectValue("OrderType", e.target.value)}>
                <option value='0'>Προβολή Εντολής </option>
                <option value='1'>Προσθήκη Σχολίων</option>
                <option value='2'>Ανανέοση</option>                               
                <option value='3'>Νεα</option>
            </Form.Control>
        );
    }
    function createAddMonth(){
        if (!paymentTypes || !obj) return null;
        return (
            <Form.Control
                as="select"
                size="sm"
                disabled ={isDisabled}
                value={getPropValue(obj, "AddMonth")}
                onChange={e => setObjectValue("AddMonth", e.target.value)}>
                <option value='6'>6</option>
                <option value='7'>7</option>
                <option value='8'>8</option>
                <option value='9'>9</option>
                <option value='10'>10</option>
                <option value='11'>11</option>
                <option value='12'>12</option>
            </Form.Control>
        );
    }
    function createRows() {
        if (!obj || !obj.OrdItems) return <tr class="table-info"><td/><td/><td/> </tr>;
        return obj.OrdItems.map((rowObj, i) => {
            return (
                <tr class="table-info">
                    <td>{rowObj["Descr"]}</td>
                    <td>
                        <NumericInput 
                          disabled ={isDisabled}  
                          min={0}
                          max={10}
                          onChange={e => setLineObjectValue("Qty",i, e.target.value) }
                          value={rowObj["Qty"]}>
                          </NumericInput>
                    </td>
                    <td>
                        <NumericInput 
                          disabled ={isDisabled}  
                          min={0}
                          max={3000}
                          onChange={e => setLineObjectValue("UnitPrice",i, e.target.value) }
                          value={rowObj["UnitPrice"]}>
                          </NumericInput>
                    </td>
                    <td>{rowObj["TotalPrice"]}</td>
                    <td>
                     <button onClick={() => deleteRow(i)} disabled ={isDisabled } ><TrashIcon/></button>
                    </td>
                </tr>      
            )
        });
    }

    async function closeClicked() {
        setScannedBarcodes([]);
        setObj(null);
        if (props.onHide) props.onHide();
    }
    async function saveClicked() {
        if (parseInt(obj.Status) ===1 ) return;
        if (parseInt(obj.OrderType) === 1 && (obj["OrderId"] ===null || obj.OrderId ===0) ){
            setError("Λάθοε τύπο ");
            return;
        }
        if ( parseInt(obj.OrderType) >0  && parseInt(obj.OrderType) <3 && obj["OrderId"] ===null){
            setError("Λάθοε τύπο ");
            return;
        }
        if ( parseInt(obj.OrderType) ===3  && (obj["OrderId"] ===null || obj["CustomerId"] ===null )  ){
            setError("Λάθοε τύπο ");
            return;
        }
        if (parseInt(obj.OrderType) > 1 && obj["PaymentTypeId"] === null ){
            setError("Δηλώσετε Τροπος Πληρομης");
            return;
        }
        if (props.onSave){ props.onSave(obj);}       
        setScannedBarcodes([]);
        if (props.onHide) props.onHide();
    }
    function hide(){
        setObj(null);
        setScannedBarcodes([]);
        if (props.onHide) props.onHide();
    }
    function createFields(obj){
        if (!obj) return;
         return (
            <div class="form-content" >
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={12}><Form.Label className="label">{obj['Name']}</Form.Label></Col>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={12}><Form.Label className="label">{obj['Location']}</Form.Label></Col>
            </Form.Group>            
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={5}><Form.Label className="label">Παραγγελία:</Form.Label></Col>
                <Col sm={7}>{createNewOrderList()}</Col>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control"  hidden={isDisabled} >
                <Col sm={5}><Form.Label className="label">Ανανέοση (Μ):</Form.Label></Col>
                <Col sm={7}>{createAddMonth()}</Col>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={5}><Form.Label className="label">Τροπος Πληρομης:</Form.Label></Col>
                <Col sm={7}>{createPaymentDropDownList()}</Col>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={8}>
                <Form.Control id="bc" disabled ={isDisabled &&  parseInt(obj.OrderType) < 2}  ref={barcodeInput} type="text" placeholder="Scan Barcode" /></Col>
                <Col sm={4}>
                {(!isDisabled &&  parseInt(obj.OrderType) >1)  ?
                    <BarcodeReader onScan={validateScan} onError={error}/>
                    : null}
                </Col>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <div class="table-responsive">
                    <table class="table table-sm table-bordered">
                        <thead>
                            <tr class="table-dark">
                                <th scope="col">Περιγραφη</th>
                                <th scope="col">Τεμ</th>                  
                                <th scope="col">Τιμη</th>
                                <th scope="col">Συνολο</th> 
                                <th scope="col"> </th>                  
                            </tr>
                        </thead>
                        <tbody>
                            {createRows()}
                        </tbody>
                    </table>
                </div>
            </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={12} className="right" ><Form.Label className="label">{" Σύνολο : " +getPropValue(obj,"TotalPrice")}</Form.Label></Col>
            </Form.Group>
                <Form.Group key="" as={Row} className="label-and-control" hidden={obj["CashFlg"]} >
                    <Col sm={12} >
                    <Form.Label className="label" hidden={obj["CashFlg"]} >{" προκαταβολή : "}</Form.Label>
                    <NumericInput
                        value={getPropValue(obj, "Deposit")}
                        onChange={e => setObjectValue("Deposit", e.target.value)}
                        min={0}
                        max={3000}
                        disabled ={isDisabled}>  
                    </NumericInput>
                    </Col>
                </Form.Group>
            <Form.Group key="" as={Row} className="label-and-control">
                <Col sm={12}>
                <Form.Control
                      as = "textarea" 
                      style={{ height: "20vh" }}
                      placeholder='Σχόλια'
                      value={getPropValue(obj, "OrdNotes")}
                      onChange={e => setObjectValue("OrdNotes", e.target.value)}
                      disabled ={isDisabled &&  notSaveFlg }  
                    />
                </Col>
            </Form.Group>
           </div>
        );
    }
    return (
        <Modal show={props.active} onHide={hide} backdrop="static" size="md">
            <Modal.Header closeButton>
                <Modal.Title>Εκτέλεση Ραντεβού</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ErrorAlert message={error} />
                {createFields(obj)}
            </Modal.Body>
            <Modal.Footer className="justify-content-between">
                <Col sm={12}>
                    <Button variant="primary" 
                        onClick={saveClicked}
                        hidden={notSaveFlg}
                        disabled ={obj ? (obj['Status'] ===1) : true } >
                        Save
                    </Button>
                    <Button className="FloatRight" variant="secondary" onClick={closeClicked}>
                        Close
                    </Button>
                </Col>
            </Modal.Footer >
        </Modal >
    )
}

export default RouteForm
